.warranty-c-dist-autocomplete {
  color: var(--color-text);
  border-radius: 2px;
  text-align: left;
  width: 100%;
  height: 30px;
  border: 1px solid #ced4da;
}
.warranty-c-dist-menu {
  background-color: #fff;
  color: #818181;
  border-radius: 2px;
  z-index: 1;
}

/* For contents styles */
.advantages-start {
  flex: 0 0 auto;
  width: 38% !important;
}
.advantages {
  flex: 0 0 auto;
  width: 62% !important;
}

.distributor-box {
  padding: 0 10%;
}

.distributor-box-buttons {
  padding: 0 10% !important;
  padding-top: 3rem !important;
}
.distributor-box-buttons-second {
  padding: 0 10% !important;
  padding-top: 2rem !important;
}

@media (max-width: 1200px) {
  .boxes-title * {
    font-size: 22px !important;
    letter-spacing: 1px !important;
  }
  .advantages-start {
    flex: 0 0 auto;
    width: 34% !important;
  }
  .advantages {
    flex: 0 0 auto;
    width: 66% !important;
  }
  .distributor-box {
    padding: 1rem;
  }
  .distributor-box-buttons {
    padding: 1rem !important;
  }
}

@media (max-width: 991px) {
  .main-page-box-text * {
    line-height: 18px !important;
  }
  .main-titles * {
    font-size: 24px !important;
    line-height: 28px !important;
    letter-spacing: 1px !important;
  }
  .second-title * {
    font-size: 20px !important;
  }
  .boxes-title * {
    font-size: 22px !important;
    letter-spacing: 1px !important;
  }
  .login-description * {
    font-size: 16px !important;
    line-height: 30px !important;
  }
  .description-text * {
    font-size: 16px !important;
    line-height: 22px !important;
    margin-bottom: 0 !important;
  }
  .responsive-login-boxes {
    max-width: 510px !important;
    margin: 0 auto;
  }
  .advantages-start {
    width: 31% !important;
  }
  .advantages {
    width: 69% !important;
  }
}

@media (max-width: 820px) {
  .advantages-start {
    width: 25% !important;
  }
  .advantages {
    width: 75% !important;
  }
}

@media (max-width: 630px) {
  .advantages-start {
    width: 20% !important;
  }
  .advantages {
    width: 80% !important;
  }
}
@media (max-width: 530px) {
  .advantages-start {
    width: 8% !important;
  }
  .advantages {
    width: 92% !important;
  }
}
@media (max-width: 350px) {
  .advantages-start {
    width: 8% !important;
  }
  .advantages {
    width: 100% !important;
  }
}

.flex-progress {
  display: flex;
  align-items: center;
  gap: 16px;
}
.step-title-mobile {
  display: inline-block !important;
  margin: 0 auto;
  width: 100%;
}
.step-title__title {
  border-left: solid 5px #cc2229;
  padding-left: 5px;
  text-transform: uppercase;
  color: #000000;
}
.step-title__title * {
  color: #000000;
  text-align: left;
}

.progress-bar {
  background-color: #000;
}

.warranty-c-alert-stl-fcm {
  /* width: 100%; */
  height: 40px !important;
  margin-top: 0px;
  position: fixed;
  top: 2%;
  left: 20%;
  width: 60%;
}

.warranty-c-fade-in-fcm {
  animation: fadeInfcm 4s;
  -webkit-animation: fadeInfcm 4s;
}

@keyframes fadeInfcm {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.textbutton {
  padding-left: 100px;
}
input {
  width: 100%;
  font-family: "DINPro-CondensedLight", sans-serif;
  font-size: 16px !important;
  color: #9b9b9b;
  padding-left: 15px;
}
.line-loggin {
  width: 30% !important;
  border-top: 1px solid #e60003 !important;
  margin-top: 5px !important;
  margin-bottom: 40px !important;
  margin-left: 35% !important;
}

@media (max-width: 930px) {
  .line-loggin {
    margin-bottom: 15px !important;
  }
}

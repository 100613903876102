.text-nav-stl {
  color: #ffffff;
  font-family: "640-font", sans-serif;
  padding-top: 30px;
  padding-bottom: 20px;
}
.text-nav-stl:link {
  font-family: "640-font", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}

.text-nav-stl:hover {
  color: #ffffff;
  background-color: red;
  padding-top: 25px;
  padding-bottom: 20px;
}

.text-nav-stl_mob {
  color: #ffffff;
  font-family: "640-font", sans-serif;
  padding-top: 10px !important;
  padding-bottom: 20px;
  align-items: center;
}
.text-nav-stl_mob:link {
  font-family: "640-font", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}

.text-nav-stl_mob:hover {
  color: #ffffff;
  background-color: red;
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-link {
  padding: 25px 10px 15px 10px;
  margin: 0;
}

.text-nav-stl-blue {
  color: #ffffff;
  font-family: "640-font", sans-serif;
  padding-top: 22px;
  padding-bottom: 20px;
}
.text-nav-stl-blue:link {
  font-family: "640-font", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}

.text-nav-stl-blue:hover {
  color: #ffffff;
  background-color: #3b5998;
  padding-top: 22px;
  padding-bottom: 20px;
}
@media (max-width: 450px) {
  .nav {
    flex-direction: column;
  }
}

.dzu-previewStatusContainer {
  width: 100% !important;
}
.dzu-previewImage {
  max-width: 100px;
  max-height: 100px;
}
.warranty-c-center-image {
  margin: 0 auto;
}

.coh-style-sbd-warranty .warranty-c-upload .dzu-dropzone {
  background: var(#e60003);
  border: var(#e60003) solid 2px;
}
.dzu-dropzone {
  height: 100px;
  max-width: 330px;
  width: 130px;
  overflow: hidden;
  background: #fff;
  border-radius: 0;
  margin-left: 0;
}
.dzu-previewStatusContainer {
  width: 0% !important;
}
.dzu-previewImage {
  width: 100%;
  height: 100px;
}

progress {
  width: 0%;
  /* position: absolute;
    bottom: 0px;
    left: 0;
    margin: 0 auto; */
}
.dzu-previewButton {
  position: absolute;
  top: 10px;
  right: 10px;
  /* background-image: url("../../../images/RemoveImage.svg"); */
}
.dzu-inputLabel {
  font-family: "640-font", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: var(--size-button-red);
  line-height: 18px;
  color: #838181;
}

.dzu-inputLabel2 {
  font-family: "640-font", sans-serif;
  font-style: normal;
  font-weight: bold;
  color: #fff;
  font-size: var(--size-button-red);
  line-height: 18px;
  color: #838181;
}

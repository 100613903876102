.warranty-c-flex {
  display: flex;
}

.warranty-c-img-ic {
  position: absolute !important;
  right: 7%;
  width: 20px;
}

.warranty-c-img-ic2 {
  position: absolute;
  right: 5% !important;
  width: 20px !important;
}
.warranty-color-text {
  color: var(--color-text);
}
.warranty-color-text-exchange {
  color: var(--color-option-exchange);
}
.warranty-color-text-primary {
  color: var(--color-primary);
}

.warranty-c-ref-autocomplete {
  color: var(--color-text);
  border-radius: 2px;
  text-align: left;
  width: 100%;
  height: 30px;
  border: 1px solid #ced4da;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.warranty-c-ref-menu {
  background-color: #fff;
  color: #818181;
  border-radius: 2px;
  line-height: 16px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

@media (max-width: 500px) {
  .warranty-c-img-ic {
    right: 12%;
  }
}

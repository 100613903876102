:root {
  --color-primary: #e60003;
  --color-primary-dark: #cc2229;
  --color-secondary: #ffffff;
  --color-text: #000000;
  --color-secondary-text: #ffffff;
  --color-background: rgb(28, 30, 31);
  --color-pass-background: #efd9ba;
  --color-pass-text: #897256;
  --color-option-exchange: #2e8540;
  --color-option-no-exchange: #e60003;
  --color-disabled: rgb(211, 211, 211);
  --color-others: #505050;
  --color-black: #000000;

  --color-text-nav: #3b5998;
  --color-ft: #e5e5e5;

  --size-heading1: 50px;
  --size-heading2: 35px;
  --size-heading3: 30px;
  --size-heading4: 18px;
  --size-heading5: 16px;
  --size-heading6: 15px;
  --size-button: 10px;
  --size-button-red: 14px;
  --size-button-clear: 12px;
  --size-login-form: 1rem;
  --size-responsive: 20px;
}
